jQuery(document).ready(function () {


    var hideBrandsBlock = $('.hide-brands');
    $('.brands-block .button').click(function(){
            if (hideBrandsBlock.is(":visible")) {
                hideBrandsBlock.animate({height: "hide"}, 600);
            } else {
                hideBrandsBlock.animate({height: 'show'}, 600);
            }
    });



    var waitForFinalEvent = (function () {
        var timers = {};
        return function (callback, ms, uniqueId) {
            if (!uniqueId) {
                uniqueId = "Don't call this twice without a uniqueId";
            }
            if (timers[uniqueId]) {
                clearTimeout(timers[uniqueId]);
            }
            timers[uniqueId] = setTimeout(callback, ms);
        };
    })();
    var slider = $('.brands-wrapper');
    function resizeHandler() {
        waitForFinalEvent(function () {
            if (window.innerWidth < 1020) {
                if (typeof slider.reloadSlider == 'function') {
                    slider.reloadSlider();
                } else {
                    slider.bxSlider({
                        "pager": false,
                        "minSlides": 3,
                        "maxSlides": 8,
                        slideWidth: 70,
                        slideMargin: 5
                    });
                }
            } else {
                if (typeof slider.destroySlider == 'function') {
                    slider.destroySlider();
                }
            }
        }, 200, 'resizeHandler');
    }

    $(window).resize(function () {
        resizeHandler();
    });
    resizeHandler();


    $('.carousel').slick({
        arrows: false,
        slidesToShow: 6,
        autoplay: true,
        autoplaySpeed: 2000
    });

    // Слайдер fl-slider
    $('.fl-slider').slick({
        respondTo: 'slider',
        arrows: true,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 700,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 500,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 300,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    });


    // Flexslider (Галерея)

    $('.flexslider').flexslider({
        animation: "slide",
        controlNav: "thumbnails",
        start: function (slider) {
            $('body').removeClass('loading');
        }
    });
});